.google-signin-button {
  border: none;
  background: transparent;
  width: 191px;
  height: 46px;
  background-image: url('../../assets/GoogleSigninDarkNormal.svg');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer
}

.google-signin-button-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}


.google-signin-button-enabled:hover {
  background-image: url('../../assets/GoogleSigninDarkFocused.svg')
}

.google-signin-button-enabled:active {
  background-image: url('../../assets/GoogleSigninDarkPressed.svg')
}

/*Preload google button images*/
.google-signin-button::after{
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url('../../assets/GoogleSigninDarkNormal.svg') url('../../assets/GoogleSigninDarkFocused.svg') url('../../assets/GoogleSigninDarkPressed.svg');
}
