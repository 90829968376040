@charset 'utf-8';
@import 'font-awesome/scss/font-awesome.scss';

$salad-background: linear-gradient(124deg, #0a2133 55.6%, #3cc 145.45%);
$primary: #fff;
$link: #b2d530;
$danger: #ef502a;
$title-color: #b2d530;
$title-weight: 900;
$title-size: 64px;
$body-overflow-y: auto;

body {
  min-height: 100vh;
  background: $salad-background;
}

.max-height-100vh {
  max-height: 100vh;
}

@import '@saladtechnologies/garden-styles';
